import React, { useState } from 'react';
import './ImageComponent.css'

const ImageComponent = ({ timeline }) => {
  const [hearts, setHearts] = useState([]);

  const handleImageClick = (event) => {
    const heart = (
      <div key={hearts.length} className="heart-animation" style={{ left: event.clientX, top: event.clientY }}>
        ❤️
      </div>
    );
    setHearts([...hearts, heart]);

    // Remove heart after animation
    setTimeout(() => {
      setHearts(hearts.filter((item, index) => index !== hearts.length - 1));
    }, 1000);
  };

  return (
    <>
      {hearts}
      <img
        className="timeline-image"
        src={timeline.imageUrl}
        alt="Timeline"
        onClick={handleImageClick}
      />
    </>
  );
};

export default ImageComponent;
