import React from 'react';
import './TimelineEntry.css'; // Import CSS file for TimelineEntry styles
import ImageComponent from './ImageComponent';



const TextComponent = ({ timeline }) => (
  <div className="timeline-text">
    <p className=' white-text'>
      <p className='timeline-date inline-block quicksand-300 uppercase-text'>{timeline.dateFormatted}</p> 
      <p className='timeline-title  uppercase-text quicksand-400'>{timeline.title}</p> 
      <p className='timeline-desc quicksand-300'>{timeline.desc}</p>
    </p>
  </div>
);



const TimelineEntry = ({ timeline, isEqual }) => {
  return (
    <li className={`timeline-entry`}>
        {isEqual ? (
          <>
            <ImageComponent timeline={timeline} positionClass="image-right" />
            <TextComponent timeline={timeline} />
          </>
        ) : (
          <>
            <TextComponent timeline={timeline} />
            <ImageComponent timeline={timeline} positionClass="image-left" />
          </>
        )}
    </li>
  );
};

export default TimelineEntry;
