import React from 'react';
import TimelineEntry from './TimelineEntry';
import './TimelineList.css'; 

const TimelinesList = ({ timelines }) => {
  return (
    <div className="timelines-list-container"> 
      <h1 className=''>Our Story</h1>
      <p className='quicksand-400 uppercase-text white-text center subheading'>Nina & Fabi</p>

      <ul>
      {timelines.map((timeline, index) => (
        <TimelineEntry 
            key={timeline.id} 
            timeline={timeline} 
            isEqual={index % 2 === 0} // Determine if the index is even or odd
        />
    ))}
      </ul>
    </div>
  );
};

export default TimelinesList;
