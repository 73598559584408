import { useEffect, useState } from 'react';
import { getFirestore, collection, query, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import TimelineEntry from './components/TimelineEntry'; // Import der neuen Komponente
import TimelinesList from './components/TimelineList';

import './fonts/Quicksand.css'
import './fonts/Allura.css'


function App() {
  const [timelines, setTimelines] = useState([]);

  useEffect(() => {
    // Get a reference to the Firestore database
    const db = getFirestore();
    const storage = getStorage();


    // Create a query for the "timelines" collection
    const timelinesCollection = collection(db, 'timelines');

    // Subscribe to changes in the "timelines" collection
    const unsubscribe = onSnapshot(timelinesCollection, (snapshot) => {
      const timelinesData = [];
      snapshot.forEach((doc) => {
        // Extract data from each document
        const data = doc.data();
        // Include the document ID in the data
        if(data.date) {
          data.dateFormatted = formatDate(data.date.seconds);
        } else {
          data.dateFormatted = ""
        }

        if (data.image) {
          // Use promise chaining instead of async/await
          getDownloadURL(ref(storage, data.image))
            .then(imageUrl => {
              data.imageUrl = imageUrl;
              timelinesData.push({ id: doc.id, ...data });
              timelinesData.sort((a, b) => a.date.seconds - b.date.seconds);

              setTimelines([...timelinesData]); // Update state
            })
            .catch(error => {
              console.error("Error getting download URL:", error);
            });
        } else {
          timelinesData.push({ id: doc.id, ...data });
          timelinesData.sort((a, b) => a.date.seconds - b.date.seconds);

          setTimelines([...timelinesData]); // Update state
        }
      });
    });

    const formatDate = (seconds) => {
      const date = new Date(seconds * 1000); // Convert seconds to milliseconds
      const day = date.getDate();
      const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
      const year = date.getFullYear();
    
      // Function to add ordinal suffix to the day
      const getOrdinalSuffix = (number) => {
        if (number === 1 || number === 21 || number === 31) return 'st';
        if (number === 2 || number === 22) return 'nd';
        if (number === 3 || number === 23) return 'rd';
        return 'th';
      };
    
      return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };
    

    // Cleanup function to unsubscribe from the collection when component unmounts
    return () => {
      unsubscribe();
    };
  }, []); // Run only on component mount

  return <TimelinesList timelines={timelines} />; // Verwenden der TimelinesList-Komponente
}

export default App;
